import {
  auth,
  db,
  FieldValue,
} from '@/firebase';
import { format } from 'date-fns';
import store from '@/store';
import Diagnosis from './diagnosis';

export default class Dayplan {
  nutritionSums = {};

  constructor(date) {
    this.selectedDate = date;
    this.nutritionSums = store.getters['dayplan/nutritionSums'];
  }

  static reference(date) {
    const userId = auth.currentUser.uid;
    const selectedDate = date ?? format(store.getters['dayplan/selectedDate'], 'yyyy-MM-dd');
    return db.collection('dayplans').doc(userId).collection('dates').doc(selectedDate);
  }

  static addFood(data) {
    const { id, date, ...item } = data;
    const isTypeMeal = item.foodType === 'meal';
    const collectionName = isTypeMeal ? 'meals' : 'foods';

    // Food can be added in different portion sizes at different times of the
    // day. So one food can be added more than once to a dayplan. There was a
    // mistake in the architectural design of the dayplan collection, so it does
    // not allow for that. We add a prefix now to differentiate between the meal
    // types to overcome this limitation. In order to not add more than one
    // prefix when re-adding a food from the list of recent items, we have to
    // store the original food ID. This is similar to the globalId, which is
    // also carried forward, but the globalId does not exist for custom created
    // food items.
    item.foodId = item.foodId ?? id;
    const key = isTypeMeal ? item.foodId : `${item.mealType}-${item.foodId}`;

    if (isTypeMeal) {
      item.ref = db.collection('meals').doc(id);
    }

    return this.reference()
      .collection(collectionName)
      .doc(key)
      .set(
        {
          ...item,
          added: FieldValue.serverTimestamp(),
        },
        { merge: true },
      )
      .then(() => {
        this.updateNutritionSums('update');
      });
  }

  static addMeal(data) {
    return this.addFood({
      ...data,
      foodType: 'meal',
    });
  }

  static removeMeal(id) {
    return this.reference()
      .collection('meals')
      .doc(id)
      .delete()
      .then(() => {
        this.updateNutritionSums('update');
      });
  }

  static updateFood(data, foodType) {
    const { id, ...item } = data;
    const collectionName = foodType === 'meal' ? 'meals' : 'foods';

    return this.reference()
      .collection(collectionName)
      .doc(id)
      .update({
        ...item,
        updated: FieldValue.serverTimestamp(),
      })
      .then(() => {
        this.updateNutritionSums('update');
      });
  }

  static removeFood(id, foodType) {
    const collectionName = foodType === 'meal' ? 'meals' : 'foods';

    return this.reference()
      .collection(collectionName)
      .doc(id)
      .delete()
      .then(() => {
        this.updateNutritionSums('update');
      });
  }

  static getFoods(daytime) {
    return this.reference()
      .collection('foods')
      .where('mealTypes', 'array-contains', daytime)
      .orderBy('updated', 'asc');
  }

  static getMeals(daytime) {
    return this.reference().collection('meals').where('mealTypes', 'array-contains', daytime);
  }

  static getFood(id) {
    return this.reference().collection('foods').doc(id);
  }

  static getFoodByDate(date, id) {
    return this.reference(date).collection('foods').doc(id);
  }

  static async updateNutritionSums(update) {
    const referenceData = await this.reference().get();
    const foods = this.reference()
      .collection('foods')
      .get()
      .then((snapshot) => snapshot.docs.map((doc) => doc.data()));

    const meals = this.reference()
      .collection('meals')
      .get()
      .then((mealsSnapshot) => {
        const mealItems = mealsSnapshot.docs.map((dayplanMeal) => dayplanMeal
          .data()
          .ref.get()
          .then((doc) => ({
            ...doc.data(),
            ...dayplanMeal.data(),
          })));

        return Promise.all(mealItems);
      });

    Promise.all([foods, meals]).then((documents) => {
      const nutritionSums = documents.flat().reduce((accumulator, docData) => {
        const { nutritions, nutritionsCalculated, mealTypes } = docData;
        const values = nutritionsCalculated || nutritions;
        Object.keys(values).forEach((key) => {
          // eslint-disable-next-line no-param-reassign
          accumulator[key] = (accumulator[key] || 0) + values[key] * mealTypes.length;
        });
        return accumulator;
      }, {});

      store.commit('dayplan/setNutritionSums', nutritionSums);

      if (update || !referenceData.data()?.timestamp) {
        const selectedDate = update
          ? Date.now()
          : format(store.getters['template/selectedDate'], 'yyyy-MM-dd');
        const timestamp = new Date(selectedDate).getTime();

        this.reference().set({ nutritionSums, timestamp }, { merge: true });
      } else {
        this.reference().set({ nutritionSums }, { merge: true });
      }
    });
  }

  static getSavedDates(userId = auth.currentUser.uid) {
    return db.collection('dayplans').doc(userId).collection('dates');
  }

  static getDateData(date) {
    const userId = auth.currentUser.uid;
    return db.collection('dayplans').doc(userId).collection('dates').doc(date);
  }

  static templateRef() {
    const userId = auth.currentUser.uid;
    const selectedDate = format(store.getters['template/selectedDate'], 'yyyy-MM-dd');
    return db.collection('dayplans').doc(userId).collection('dates').doc(selectedDate);
  }

  static async getRecentDayplans() {
    const userId = auth.currentUser.uid;

    const reference = await db
      .collection('dayplans')
      .doc(userId)
      .collection('dates')
      .orderBy('timestamp', 'desc')
      .limit(30)
      .get();

    return reference;
  }

  static getRelevantNutrients(nutritionsData) {
    const diagnosis = new Diagnosis(store.getters['user/diagnosisName']);
    const requiredNutritionsKeys = ['carbs', 'calories', 'fat'];
    const useOptionalKeys = store.getters['user/useOptionalNutritionValues'];
    const requiredNutritions = [diagnosis.nutritionKey];
    const relevantNutrients = {};

    requiredNutritionsKeys.unshift(diagnosis.nutritionKey);

    if (useOptionalKeys && diagnosis?.optionalNutritionKeys) {
      requiredNutritions.unshift(...diagnosis.optionalNutritionKeys);
      requiredNutritionsKeys.unshift(...diagnosis.optionalNutritionKeys);
    }

    requiredNutritionsKeys.splice(requiredNutritions.length, 0, 'protein');

    requiredNutritionsKeys.forEach((key) => {
      relevantNutrients[key] = nutritionsData?.[key] ? nutritionsData[key] : 0;
    });

    return relevantNutrients;
  }
}
